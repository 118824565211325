var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "preview-layout-container" }, [
    _c("div", { staticClass: "layout-header" }, [
      _c("div", { staticClass: "layout-lesson" }, [
        _c("div", {
          staticClass: "student-left-back",
          on: { click: _vm.backToHome },
        }),
        _c("div", { staticClass: "handle-word-wrap font-lesson-details" }, [
          _vm._v(" LESSON " + _vm._s(_vm.lessonInfo.lesson) + " "),
        ]),
        _c(
          "ul",
          { staticClass: "progressBar" },
          _vm._l(_vm.lessonInfo.totalPage, function (item, index) {
            return _c("li", { key: index }, [
              _c("span", {
                staticClass: "progressDot",
                class: {
                  progressDotFinish: item <= _vm.pageNum,
                  progressDotCurrent: item === _vm.pageNum,
                },
                on: {
                  click: function ($event) {
                    return _vm.jumpPage(item)
                  },
                },
              }),
            ])
          }),
          0
        ),
      ]),
    ]),
    _c("div", { staticClass: "layout-content" }, [
      _c("div", { staticClass: "layout-content-left" }, [
        _c("div", { staticClass: "layout-content-left-header" }, [
          _c(
            "div",
            { staticClass: "page-title-box" },
            [
              _vm._l(_vm.lessonInfo.pageTitleArray, function (item, index) {
                return [
                  index + 1 === _vm.pageNum
                    ? _c(
                        "div",
                        {
                          key: index + "page",
                          staticClass: "page-title",
                          class: {
                            active:
                              _vm.pageNum === index + 1 && _vm.titleType === 0,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.switchPage(0)
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "font-lesson-title" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  item.target && index + 1 === _vm.pageNum
                    ? _c(
                        "div",
                        {
                          key: index,
                          staticClass: "page-title",
                          class: {
                            active:
                              _vm.pageNum === index + 1 && _vm.titleType === 1,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.switchPage(1)
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "font-lesson-title" }, [
                            _vm._v(_vm._s(item.target ? item.target : "")),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _c("div", { staticClass: "control-box" }, [
            _c("div", { staticClass: "page-turning" }, [
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.pageNum > 1,
                    expression: "pageNum > 1",
                  },
                ],
                staticClass: "previous",
                on: { click: _vm.prevClick },
              }),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.pageNum < _vm.lessonInfo.totalPage,
                    expression: "pageNum < lessonInfo.totalPage",
                  },
                ],
                staticClass: "nextPrevious",
                on: { click: _vm.nextClick },
              }),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            ref: "interactionCanvasBox",
            staticClass: "layout-content-left-display",
          },
          [_c("RouterView"), _c("div", { staticClass: "mask" })],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "layout-content-right" },
        [_c("PreviewRight")],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }