export const lessonDetail = [{
        lesson: 1,
        totalPage: 10,
        pageTitleArray: [{
                title: "主题一 | Chapter 1",
                isTheme: false
            },
            {
                title: "第一课 | Lesson 1",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },
            {
                title: "认一认 | Learn",
                isTheme: false
            },
            {
                title: "认一认 | Learn",
                isTheme: false
            },
            {
                title: "认一认 | Learn", // ni  hao 拆分
                isTheme: false
            },
            {
                title: "认一认 | Learn",
                isTheme: false
            },
            {
                title: "认一认 | Learn",
                isTheme: false
            },
            {
                title: "词卡 | Word cards",
                isTheme: false
            },
            {
                title: "总结页 | Summary",
                isTheme: false
            },
            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 2,
        totalPage: 7,
        pageTitleArray: [{
                title: "主题1 | Chapter 1"
            },
            {
                title: "第二课 | Lesson 2",
                target: "课程目标 | Syllabus",
            },
            {
                title: "认一认 | Review"
            },
            {
                title: "认一认 | Review"
            },
            {
                title: "写一写 | Review"
            },
            {
                title: "认一认 | Review"
            },
            {
                title: "再见页 | Review"
            },

        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 3,
        totalPage: 11,
        pageTitleArray: [{
                title: "主题一 | Chapter 1",
                isTheme: false
            },

            {
                title: "第三课 | Lesson 3",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },
            {
                title: "认一认 | Learn",
                isTheme: false
            },
            {
                title: "认一认 | Learn",
                isTheme: false
            },
            {
                title: "认一认 | Learn",
                isTheme: false
            },
            {
                title: "认一认 | Learn",
                isTheme: false
            },
            {
                title: "认一认 | Learn",
                isTheme: false
            },
            {
                title: "认一认 | Learn",
                isTheme: false
            },
            {
                title: "词卡 | Word cards",
                isTheme: false
            },
            {
                title: "总结页 | Summary",
                isTheme: false
            },
            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 4,
        totalPage: 7,
        pageTitleArray: [{
                title: "主题1 | Chapter 1"
            },
            {
                title: "第二课 | Lesson 4",
                target: "课程目标 | Syllabus",
            },
            {
                title: "认一认 | Review"
            },
            {
                title: "写一写 | Review"
            },
            {
                title: "写一写 | Review"
            },
            {
                title: "认一认 | Review"
            },
            {
                title: "再见页 | Review"
            },

        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 5,
        totalPage: 11,
        pageTitleArray: [{
                title: "主题一 | Chapter 1",
                isTheme: false
            },

            {
                title: "第五课 | Lesson 5",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },
            {
                title: "导入 | Introduction",
                isTheme: false
            },

            {
                title: "认一认 | Learn",
                isTheme: false
            },
            {
                title: "认一认 | Learn",
                isTheme: false
            },

            {
                title: "认一认 | Learn",
                isTheme: false
            },
            {
                title: "认一认 | Learn",
                isTheme: false
            },
            {
                title: "认一认 | Learn",
                isTheme: false
            },



            {
                title: "词卡 | Word cards",
                isTheme: false
            },
            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 6,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题一 | Chapter 1"
            },

            {
                title: "第六课 | Lesson 6",
                target: "课程目标 | Syllabus",
            },
            {
                title: "认一认 | Review"
            },
            {
                title: "认一认 | Review"
            },
            {
                title: "写一写 | Review"
            },
            {
                title: "写一写 | Review"
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye"
            },
        ],
        // showSyllabusPage: true,
    },

    {
        lesson: 7,
        totalPage: 17,
        pageTitleArray: [{
                title: "主题一 | Chapter 1",
                isTheme: false
            },

            {
                title: "第七课 | Lesson 7",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },


            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "词卡 | Word cards",
                isTheme: false
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },
            {
                title: "闯关图 | Map",
                isTheme: false
            },
            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 8,
        totalPage: 24,
        pageTitleArray: [{
                title: "主题一 | Chapter 1",
                isTheme: false
            },
            {
                title: "闯关图 | Map",
                isTheme: false
            },
            {
                title: "第八课 | Lesson 8",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },
            {
                title: "复习巩固 | Review",
                isTheme: true,
            },
            {
                title: "复习巩固 | Review",
                isTheme: false
            },
            {
                title: "练一练 | Practice",
                isTheme: false
            },
            {
                title: "复习巩固 | Review",
                isTheme: true,
            },
            {
                title: "说一说 | Speak",
                isTheme: false
            },
            {
                title: "练一练 | Practice",
                isTheme: false
            },
            {
                title: "说一说 | Speak",
                isTheme: false
            },
            {
                title: "练一练 | Practice",
                isTheme: false
            },
            {
                title: "练一练 | Practice",
                isTheme: false
            },
            {
                title: "说一说 | Speak",
                isTheme: false
            },
            {
                title: "复习巩固 | Review",
                isTheme: true,
            },
            {
                title: "练一练 | Practice",
                isTheme: false
            },
            {
                title: "练一练 | Practice",
                isTheme: false
            },
            {
                title: "复习巩固 | Review",
                isTheme: true,
            },

            {
                title: "说一说 | Speak",
                isTheme: false
            },
            {
                title: "练一练 | Practice",
                isTheme: false
            },
            {
                title: "练一练 | Practice",
                isTheme: false
            },
            {
                title: "练一练 | Practice",
                isTheme: false
            },
            {
                title: "词卡 | Word cards",
                isTheme: false
            },
            {
                title: "闯关图 | Map",
                isTheme: false
            },
            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 9,
        totalPage: 9,
        pageTitleArray: [{
                title: "主题二 | Chapter 2",
                isTheme: false
            },

            {
                title: "第九课 | Lesson 9",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Learn",
                isTheme: false
            },
            {
                title: "认一认 | Learn",
                isTheme: false
            },
            {
                title: "认一认 | Learn",
                isTheme: false
            },

            {
                title: "认一认 | Learn",
                isTheme: false
            },

            {
                title: "词卡 | Word cards",
                isTheme: false
            },
            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 10,
        totalPage: 6,
        pageTitleArray: [{
                title: "主题二 | Chapter 2"
            },

            {
                title: "第十课 | Lesson 10",
                target: "课程目标 | Syllabus",
            },

            {
                title: "认一认 | Learn"
            },
            {
                title: "认一认 | Learn"
            },
            {
                title: "总结页 | Summary"
            },

            {
                title: "再见页 | Bye-bye"
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 11,
        totalPage: 10,
        pageTitleArray: [{
                title: "主题二 | Chapter 2",
                isTheme: false
            },

            {
                title: "第十一课 | Lesson 11",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Learn",
                isTheme: false
            },
            {
                title: "认一认 | Learn",
                isTheme: false
            }, {
                title: "认一认 | Learn",
                isTheme: false
            }, {
                title: "认一认 | Learn",
                isTheme: false
            },
            {
                title: "词卡 | Word cards",
                isTheme: false
            },
            {
                title: "总结页 | Summary"
            },

            {
                title: "再见页 | Bye-bye"
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 12,
        totalPage: 7,
        pageTitleArray: [{
                title: "主题二 | Chapter 2",
                isTheme: false
            },

            {
                title: "第十二课 | Lesson 12",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Learn",
                isTheme: false
            },
            {
                title: "写一写 | Learn",
                isTheme: false
            },
            {
                title: "写一写 | Learn",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },


            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 13,
        totalPage: 10,
        pageTitleArray: [{
                title: "主题二 | Chapter 2",
                isTheme: false
            },

            {
                title: "第十三课 | Lesson 13",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Learn",
                isTheme: false
            },
            {
                title: "认一认 | Learn",
                isTheme: false
            },
            {
                title: "认一认 | Learn",
                isTheme: false
            },
            {
                title: "认一认 | Learn",
                isTheme: false
            },
            {
                title: "认一认 | Learn",
                isTheme: false
            },

            {
                title: "词卡 | Word cards",
                isTheme: false
            },
            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 14,
        totalPage: 5,
        pageTitleArray: [{
                title: "主题二 | Chapter 2"
            },

            {
                title: "第十四课 | Lesson 14",
                target: "课程目标 | Syllabus",
            },
            {
                title: "练一练 | Practice",
            },
            {
                title: "总结页 | Summary"
            },

            {
                title: "再见页 | Bye-bye"
            },
        ],
        // showSyllabusPage: true,
    },

    {
        lesson: 15,
        totalPage: 9,
        pageTitleArray: [{
                title: "主题二 | Chapter 2",
                isTheme: false
            },

            {
                title: "第十五课 | Lesson 15",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "词卡 | Word cards",
                isTheme: false
            },
            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 16,
        totalPage: 9,
        pageTitleArray: [{
                title: "主题二 | Chapter 2",
                isTheme: false
            },

            {
                title: "第十五课 | Lesson 15",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "词卡 | Word cards",
                isTheme: false
            },
            {
                title: "总结页 | Summary",
                isTheme: false
            },
            {
                title: "闯关图 | Map",
                isTheme: false
            },
            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 17,
        totalPage: 9,
        pageTitleArray: [{
                title: "主题三 | Chapter 3",
                isTheme: false
            },

            {
                title: "第十七课 | Lesson 17",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "词卡 | Word cards",
                isTheme: false
            },
            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },

        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 18,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题二 | Chapter 3",
                isTheme: false
            },

            {
                title: "第十八课 | Lesson 18",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 19,
        totalPage: 9,
        pageTitleArray: [{
                title: "主题三 | Chapter 3",
                isTheme: false
            },

            {
                title: "第十九课 | Lesson 19",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "词卡 | Word cards",
                isTheme: false
            },
            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 20,
        totalPage: 9,
        pageTitleArray: [{
                title: "主题三 | Chapter 3",
                isTheme: false
            },

            {
                title: "第二十课 | Lesson 20",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 21,
        totalPage: 9,
        pageTitleArray: [{
                title: "主题三 | Chapter 3",
                isTheme: false
            },

            {
                title: "第二十一课 | Lesson 21",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "词卡 | Word cards",
                isTheme: false
            },
            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 22,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题三 | Chapter 3",
                isTheme: false
            },

            {
                title: "第二十二课 | Lesson 22",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 23,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题三 | Chapter 3",
                isTheme: false
            },

            {
                title: "第二十三课 | Lesson 23",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 24,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题三 | Chapter 3",
                isTheme: false
            },

            {
                title: "第二十三课 | Lesson 23",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 25,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题四 | Chapter 4",
                isTheme: false
            },

            {
                title: "第二十五课 | Lesson 25",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 26,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题4 | Chapter 4",
                isTheme: false
            },

            {
                title: "第二十六课 | Lesson 26",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 27,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题四 | Chapter 4",
                isTheme: false
            },

            {
                title: "第二十七课 | Lesson 27",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 28,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题四 | Chapter 4",
                isTheme: false
            },

            {
                title: "第二十八课 | Lesson 28",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 29,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题四 | Chapter 4",
                isTheme: false
            },

            {
                title: "第二十九课 | Lesson 29",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 30,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题四 | Chapter 4",
                isTheme: false
            },

            {
                title: "第三十课 | Lesson 30",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 31,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题四 | Chapter 4",
                isTheme: false
            },

            {
                title: "第三十一课 | Lesson 31",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 32,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题四 | Chapter 4",
                isTheme: false
            },

            {
                title: "第三十二课 | Lesson 32",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 33,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题五 | Chapter 5",
                isTheme: false
            },

            {
                title: "第三十三课 | Lesson 33",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 34,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题五 | Chapter 5",
                isTheme: false
            },

            {
                title: "第三十四课 | Lesson 34",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 35,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题五 | Chapter 5",
                isTheme: false
            },

            {
                title: "第三十五课 | Lesson 35",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 36,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题五 | Chapter 5",
                isTheme: false
            },

            {
                title: "第三十六课 | Lesson 36",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 37,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题五 | Chapter 5",
                isTheme: false
            },

            {
                title: "第三十七课 | Lesson 37",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 38,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题五 | Chapter 5",
                isTheme: false
            },

            {
                title: "第三十八课 | Lesson 38",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 39,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题五 | Chapter 5",
                isTheme: false
            },

            {
                title: "第三十九课 | Lesson 39",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 40,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题五 | Chapter 5",
                isTheme: false
            },

            {
                title: "第四十课 | Lesson 40",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 41,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题六 | Chapter 6",
                isTheme: false
            },

            {
                title: "第四十一课 | Lesson 41",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 42,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题六 | Chapter 6",
                isTheme: false
            },

            {
                title: "第四十二课 | Lesson 42",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 43,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题六 | Chapter 6",
                isTheme: false
            },

            {
                title: "第四十三课 | Lesson 43",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 44,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题六 | Chapter 6",
                isTheme: false
            },

            {
                title: "第四十四课 | Lesson 44",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 45,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题六 | Chapter 6",
                isTheme: false
            },

            {
                title: "第四十五课 | Lesson 45",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 46,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题六 | Chapter 6",
                isTheme: false
            },

            {
                title: "第四十六课 | Lesson 46",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 47,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题六 | Chapter 6",
                isTheme: false
            },

            {
                title: "第四十七课 | Lesson 47",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 48,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题六 | Chapter 6",
                isTheme: false
            },

            {
                title: "第四十八课 | Lesson 48",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 49,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题七 | Chapter 7",
                isTheme: false
            },

            {
                title: "第四十九课 | Lesson 49",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 50,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题七 | Chapter 7",
                isTheme: false
            },

            {
                title: "第五十课 | Lesson 50",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 51,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题7 | Chapter 7",
                isTheme: false
            },

            {
                title: "第五十一课 | Lesson 51",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 52,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题七 | Chapter 7",
                isTheme: false
            },

            {
                title: "第五十二课 | Lesson 52",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 53,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题七 | Chapter 7",
                isTheme: false
            },

            {
                title: "第五十三课 | Lesson 53",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 54,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题七 | Chapter 7",
                isTheme: false
            },

            {
                title: "第五十四课 | Lesson 54",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 55,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题七 | Chapter 7",
                isTheme: false
            },

            {
                title: "第五十五课 | Lesson 55",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 56,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题七 | Chapter 7",
                isTheme: false
            },

            {
                title: "第五十六课 | Lesson 56",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 57,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题八 | Chapter 8",
                isTheme: false
            },

            {
                title: "第五十七课 | Lesson 57",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 58,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题八 | Chapter 8",
                isTheme: false
            },

            {
                title: "第五十八课 | Lesson 58",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 59,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题八 | Chapter 8",
                isTheme: false
            },

            {
                title: "第五十九课 | Lesson 59",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 60,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题八 | Chapter 8",
                isTheme: false
            },

            {
                title: "第六十课 | Lesson 60",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 61,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题八 | Chapter 8",
                isTheme: false
            },

            {
                title: "第六十一课 | Lesson 61",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 62,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题八 | Chapter 8",
                isTheme: false
            },

            {
                title: "第六十二课 | Lesson 62",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },
    {
        lesson: 63,
        totalPage: 8,
        pageTitleArray: [{
                title: "主题八 | Chapter 8",
                isTheme: false
            },

            {
                title: "第六十三课 | Lesson 63",
                target: "课程目标 | Syllabus",
                isTheme: true,
            },

            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },
            {
                title: "认一认 | Recognize",
                isTheme: false
            },

            {
                title: "总结页 | Summary",
                isTheme: false
            },

            {
                title: "再见页 | Bye-bye",
                isTheme: false
            },
        ],
        // showSyllabusPage: true,
    },

]