<template>
  <div class="preview-container">
    <PreviewLayout />
  </div>
</template>

<script>
import PreviewLayout from "@/components/Course/Layout/PreviewLayout.vue";
export default {
  name: "PreviewPage",
  components: {
    PreviewLayout,
  },
};
</script>
<style lang="scss" scoped>
.preview-container {
  width: 100%;
  height: 100%;
}
</style>