<template>
  <div class="interaction-container">
    <div class="video-content">
      <div class="content remote-video">
        <div class="avatar-img">
          <img :src="this.teacherAvatar" alt="" />
        </div>
        <div class="name-text">{{ this.teacherName }}</div>
      </div>
      <!-- 自己 -->
      <div class="content local-video">
        <div class="avatar-img">
          <img :src="this.handleImag" alt="" />
        </div>
        <div class="name-text">{{ this.userInfo.userSetting.nickname }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
     
      teacherName:'',
      handleImag: null,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.dashboard.userInfo,
      isKid: (state) => state.dashboard.isKid,
      kidUserId: (state) => state.dashboard.kidUserId,
      teacherAvatar: (state) => state.dashboard.teacherAvatar,
    }),  
  },
  
  mounted() {
    this.teacherName=localStorage.getItem('teacherName')
    // this.teacherName=this.$route.params.teacherName
    this.userInfo.familyMember.forEach((item, index) => {
      if (this.kidUserId === item.id) {
        this.handleImag = this.userInfo.familyMember[index].photo;
      }
    });
  },
  
};
</script>

<style scoped lang="scss">
.interaction-container {
  width: 100%;
  height: 100%;
  position: relative;
  .video-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    box-sizing: border-box;
    position: relative;
    .remote-video {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .avatar-img {
        max-width: 80px;
        color: #fff;
        img {
          width: 100%;
          border-radius: 50%;
        }
      }
      .name-text {
        margin-top: 10px;
        color: #ffffff;
        opacity: 62%;
      }
    }
    .local-video {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 5px;
      margin-bottom: 0 !important;
      .avatar-img {
        max-width: 80px;
        color: #fff;
        img {
          width: 100%;
          border-radius: 50%;
        }
      }
      .name-text {
        margin-top: 10px;
        color: #ffffff;
        opacity: 62%;
      }
    }
    .content {
      width: 100%;
      height: calc(50% - 5px);
      border-radius: 20px;
      // overflow: hidden;
      background-color: #0e2f64;
      position: relative;
    }
  }
}
// spin animation

</style>
