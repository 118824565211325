
<template>
  <!-- preview-layout-container -->
  <div class="preview-layout-container">
    <div class="layout-header">
      <div class="layout-lesson">
        <div class="student-left-back" @click="backToHome"></div>
        <div class="handle-word-wrap font-lesson-details">
          LESSON {{ lessonInfo.lesson }}
        </div>
        <!-- progressDotCurrent: item.current, -->
        <ul class="progressBar">
          <li v-for="(item, index) in lessonInfo.totalPage" :key="index">
            <span
              :class="{
                progressDotFinish: item <= pageNum,
                progressDotCurrent: item === pageNum,
              }"
              class="progressDot"
              @click="jumpPage(item)"
            ></span>
          </li>
        </ul>
      </div>
    </div>
    <div class="layout-content">
      <div class="layout-content-left">
        <!-- page 标题 -->

        <div class="layout-content-left-header">
          <!-- 课程标题 -->
          <div class="page-title-box">
            <template v-for="(item, index) in lessonInfo.pageTitleArray">
              <div
                class="page-title"
                v-if="index + 1 === pageNum"
                :class="{ active: pageNum === index + 1 && titleType === 0 }"
                @click="switchPage(0)"
                :key="index + 'page'"
              >
                <span class="font-lesson-title">{{ item.title }}</span>
              </div>
              <div
                class="page-title"
                :key="index"
                v-if="item.target && index + 1 === pageNum"
                :class="{ active: pageNum === index + 1 && titleType === 1 }"
                @click="switchPage(1)"
              >
                <span class="font-lesson-title">{{
                  item.target ? item.target : ""
                }}</span>
              </div>
            </template>
          </div>

          <!-- 白板控制器  翻页 -->
          <div class="control-box">
            <div class="page-turning">
              <div
                class="previous"
                @click="prevClick"
                v-show="pageNum > 1"
              ></div>
              <div
                class="nextPrevious"
                @click="nextClick"
                v-show="pageNum < lessonInfo.totalPage"
              ></div>
            </div>
          </div>
        </div>

        <!-- 课程主体部分 -->

        <div class="layout-content-left-display" ref="interactionCanvasBox">
          <RouterView />
          <div class="mask"></div>
        </div>
      </div>
      <div class="layout-content-right">
        <!-- todolist: 需要做传值修改 -->
        <PreviewRight />
      </div>
    </div>
  </div>
</template>
<script>
import { lessonDetail } from "@/assets/js/default/preview.js";
import PreviewRight from "@/components/Course/PreviewRight";

export default {
  name: "PreviewLayout",
  components: {
    PreviewRight,
  },
  data() {
    return {
      pageNum: 1, // 默认第一页
      lessonInfo: {},
      routeIndex: "",

      titleType: 0, // 默认课程类型 0 课程  1 目标  2 白板
    };
  },
  watch: {
    $route: {
      handler(value) {
        // console.log(value, "url变化");
        const index = parseInt(value.name.split("PreviewLesson")[1]);
        this.routeIndex = index;
        if (this.routeIndex === 999) {
          this.lessonInfo = lessonDetail[lessonDetail.length - 1];
        } else {
          this.lessonInfo = lessonDetail[index - 1];
        }
        // console.log(this.lessonInfo);
        this.pageNum = 1;
      },
      immediate: true,
    },
    pageNum(value) {
      this.$bus.$emit("previewJumpPage", value);
    },
  },
  created() {
    // const index = parseInt(this.$route.name.split("previewlesson")[1]);
    this.lessonInfo = lessonDetail[this.routeIndex - 1];
    this.pageNum = 1;
  },
  beforeDestroy() {
    this.$bus.$off("changePageIndex");
  },
  methods: {
    backToHome() {
      this.$router.go(-1);
    },

    switchPage(value) {
      this.titleType = value;
      this.$bus.$emit("previewSwitchPageType", value);
    },

    // 跳页面
    jumpPage(index) {
      this.pageNum = index;
    },

    // 切换白板和课程页面
    nextClick() {
      // console.log("下一页");
      this.pageNum++;
    },
    prevClick() {
      // console.log("上一页");
      this.pageNum--;
    },
  },
};
</script>
>

<style lang="scss" scoped>
@import "@/components/Course/Layout/preview.scss";
.mask {
  position: fixed;
  top: 15%;
  bottom: 0;
  left: 0;
  right: 35%;
  opacity: 0;
  z-index: 9999;
}
</style>
